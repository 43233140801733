import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "main.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Welcome"
        lang="en"
        description="Holiday cottage in the Lake District"
      />
      <h1>Welcome to Treetops</h1>
      <h2>Holiday cottage in the Lake District</h2>
      <p>Intro text about how interesting and cool treetops is</p>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Img fluid={data.mainImage.childImageSharp.fluid} />
      </div>
    </Layout>
  )
}

export default IndexPage
